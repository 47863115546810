import React from "react"
import BaseLayout from "./../Layouts/BaseLayout"
import Head from "./../components/Head/Head"

const NotFoundPage = props => {
  return (
    <BaseLayout>
      <Head pageTitle="Page not found" />
      <div className="row" style={{ height: "80vh" }}>
        <div
          className="col-12 d-flex flex-column align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <p className="display-4">Page Not Found</p>
          <p style={{ fontSize: "1.2em" }}>
            Oops, there is nothing in this location.
          </p>
          <div className="w-70 mx-auto text-center">
            <img
              style={{ width: "25%" }}
              src={require("./../assets/not-found.svg")}
              alt="Not Found"
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default NotFoundPage
